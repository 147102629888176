import React, { useContext, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { ReporteCostosActivosContext } from '.';
import formatNumber from 'format-number';
import CustomMiniCards from '../../../components/customMiniCards';
import ConfiguracionCostos from './configuracionCostos';
const numberFormater = formatNumber({ integerSeparator: '.', decimal: ',' });

export default function IndicadoresCostosActivos(props) {
  const { indicadores, isLoading } = useContext(ReporteCostosActivosContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true); // Abrir el diálogo
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Cerrar el diálogo
  };

  return (
    <>
      {isLoading && (
        <Grid container>
          <Grid item lg style={{ textAlign: 'center' }}>
            <CircularProgress size={60} />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={4}>
            <CustomMiniCards title="Indicadores - Resumen de Costos por Activo">
              <Typography variant="h6">{`Cantidad: ${numberFormater(indicadores.cantidad)}`}</Typography>
              <br />
              <Typography variant="h6">{`Costos: $${numberFormater(indicadores.costoTotal)}`}</Typography>
            </CustomMiniCards>
          </Grid>
        </Grid>
      )}

      {/* Botón para abrir el diálogo */}
      <Grid container justifyContent="center" style={{ marginTop: 20 }}>
        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
          Configuración de Parámetros para Calculo
        </Button>
      </Grid>

      {/* Componente del diálogo */}
      <ConfiguracionCostos open={dialogOpen} close={handleCloseDialog} />
    </>
  );
}
