import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
// import ListadoArchivosValoresLibros from './listadoArchivosValoresLibros';
import ConfiguracionCostos from './configuracionCostos';
// import UpdateValorLibro from '../updateValorLibro';
import ListadoArchivosValoresLibros from '../updateValorLibro/listadoArchivosValoresLibros';
import SubirArchivoValorLibro from '../updateValorLibro/subirArchivoValorLibro';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Index(props) {
  const { open, close } = props;

  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const smBreak = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog open={open} maxWidth="xl" fullWidth>
      <DialogTitle>
        <Typography variant="h6" align="center">
          Configuración de opciones generales para el cálculo
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="basic tabs"
              centered={!smBreak}
              variant={smBreak ? 'scrollable' : 'standard'}
              scrollButtons={smBreak ? 'on' : 'off'}
            >
              <Tab label="Opciones para Cálculo de Costos" {...a11yProps(0)} />
              <Tab label="Registro Valor Libro Contable" {...a11yProps(1)} />
              <Tab label="Histórico de carga archivos valores libro" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            <ConfiguracionCostos />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <SubirArchivoValorLibro />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <ListadoArchivosValoresLibros />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={close}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Index;
