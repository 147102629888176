import React, { useContext } from 'react';
import { Grid, TextField, InputAdornment, IconButton, Button, Box } from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { VER_EDITAR_CONTABILIDAD } from '../../../constants/permissions';
import { MainContext } from '../../../App';
import { BIENES_GERENCIA } from '../../../constants/routes';

function TabContabilidad(props) {
  const { bien, factura, handleVerArchivoFactura, handle_dialog_actualizar_contabilidad } = props;

  const { permisos } = useContext(MainContext);
  console.log('bien pasado: ', bien);
  console.log('factura pasado: ', factura);
  return (
    <Grid container spacing={1}>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Código contable"
          fullWidth
          variant={'outlined'}
          margin={'normal'}
          value={bien.codigo_contable}
          disabled
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Proveedor"
          fullWidth
          variant={'outlined'}
          margin={'normal'}
          value={factura.proveedor ? factura.proveedor.nombre : ''}
          disabled
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="N° Factura"
          fullWidth
          variant={'outlined'}
          margin={'normal'}
          value={factura.numero}
          disabled
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <CurrencyTextField
          label="Monto Factura"
          value={factura.valor}
          fullWidth
          variant="outlined"
          margin={'normal'}
          currencySymbol={
            bien.activo.moneda === 'CLP'
              ? '$'
              : bien.activo.moneda === 'PEN'
              ? 'S/'
              : bien.activo.moneda === 'USD'
              ? 'USD'
              : '$'
          }
          outputFormat="string"
          decimalCharacter=","
          digitGroupSeparator="."
          leading="deny"
          decimalPlaces={2}
          decimalPlacesShownOnBlur={2}
          decimalPlacesShownOnFocus={4}
          disabled
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          label="Fecha Factura"
          fullWidth
          variant={'outlined'}
          margin={'normal'}
          value={factura.fecha}
          disabled
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Ver Archivo"
                  onClick={() => handleVerArchivoFactura(factura.archivo)}
                  edge="end"
                  disabled={!factura.archivo || !factura.archivo.url ? true : false}
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Factura"
          fullWidth
          variant={'outlined'}
          margin={'normal'}
          value={factura.archivo ? factura.archivo.nombre : ''}
          disabled
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField label="Sociedad" fullWidth variant={'outlined'} margin={'normal'} value={bien.sociedad} disabled />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          {permisos && permisos[VER_EDITAR_CONTABILIDAD] && (
            <Button
              onClick={handle_dialog_actualizar_contabilidad}
              variant="contained"
              color="primary"
              style={{ margin: 20 }}
            >
              {'Editar Contabilidad'}
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default TabContabilidad;
