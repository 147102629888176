import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
  Typography,
  Tooltip,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
} from '@material-ui/core';
import FileInput from '../../../components/fileInput';
import MaterialReactSelect from '../../../components/materialReactSelect';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import TablaDocumentosAdjuntos from './tablaDocumentosAdjuntos';
import DialogoAgregarProveedor from './dialogoAgregarProveedor';
import { Add } from '@material-ui/icons';
import { MainContext } from '../../../App';
import { ASIGNAR_ACTIVOS_PERSONAS } from '../../../constants/permissions';
import { RegistroBienesContext } from './index';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1, 1, 3, 1),
  },
  center: {
    textAlign: 'center',
  },
  main: {
    overflow: 'auto',
    maxWidth: '95vw',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  tableHeader: {
    textAlign: 'center',
    border: '1px solid #E0E0E0',
    fontWeight: 'bold',
    color: '#731f1f',
    position: 'sticky',
  },
  tableCell: {
    border: '1px solid #E0E0E0',
  },
  btnMargin: {
    margin: theme.spacing(1),
  },
  fileInput: {
    top: 'unset',
    left: 'unset',
    position: 'inherit',
  },
}));

export default function PageFormularioBasicoBienes(props) {
  const { formik, proveedores, handleVolver, refreshProveedores, modoMultiplesActivos } = props;
  const { errors, handleChange, touched, values, handleSubmit } = formik;
  const { formularioBasico } = useContext(RegistroBienesContext);
  const classes = useStyles();
  const fileChange = (name, e) => {
    formik.setFieldValue(name, e.target.files[0]);
    formik.setFieldTouched(name, true, false);
  };
  const dateChange = (name, e) => {
    formik.setFieldValue(name, e);
    formik.setFieldTouched(name, true, false);
  };

  const [openDialogoProveedor, setOpenDialogoProveedor] = useState(false);

  const { permisos } = useContext(MainContext);

  // Verifica si la gerencia es de Perú
  const esGerenciaPeru = formularioBasico?._gerencia_ref === '655615b5b6eb2a0011438b4e';

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Typography variant="h5">Facturación</Typography>
      <Grid container spacing={1}>
        <Grid item lg={4} md={6} sm={6} xs={12} style={{ display: 'flex' }}>
          <MaterialReactSelect
            label="Proveedor"
            isClearable
            onChange={(newValue, actionMeta) =>
              newValue ? formik.setFieldValue('proveedor', newValue) : formik.setFieldValue('proveedor', '')
            }
            placeholder="Seleccionar un Proveedor"
            options={
              proveedores &&
              proveedores.data.map((value, index) => ({
                value,
                label: '[' + value.rut + '] ' + value.nombre,
              }))
            }
            noOptionsMessage={() => 'No ha encontrado el proveedor'}
            error={touched.proveedor && errors.proveedor && Boolean(errors.proveedor)}
            helperText={touched.proveedor && errors.proveedor ? errors.proveedor : ''}
          />
          <Tooltip title="Agregar Proveedor">
            <Button
              variant="contained"
              style={{ margin: '18px 4px 14px 4px', boxShadow: 'none' }}
              color="primary"
              onClick={() => setOpenDialogoProveedor(true)}
            >
              <Add />
            </Button>
          </Tooltip>
        </Grid>
        {/* <Grid item lg={4} md={6} sm={6} xs={12}>
          <Button variant="contained" color="primary" onClick={() => setOpenDialogoProveedor(true)}>Agregar Proveedor</Button>

        </Grid> */}
        <Grid item lg={4} md={4} sm={4} xs={8}>
          <TextField
            name="numero"
            label="N° Factura"
            fullWidth
            variant={'outlined'}
            margin={'normal'}
            value={values.numero}
            onChange={handleChange}
            error={touched.numero && errors.numero && Boolean(errors.numero)}
            helperText={touched.numero && errors.numero ? errors.numero : ''}
          />
        </Grid>
        {esGerenciaPeru && (
          <Grid item lg={1} md={1} sm={1} xs={4}>
            <FormControl fullWidth variant="outlined" margin="normal" error={touched.moneda && Boolean(errors.moneda)}>
              <InputLabel>Moneda</InputLabel>
              <Select name="moneda" value={values.moneda || 'PEN'} onChange={handleChange} label="Moneda">
                <MenuItem value="CLP">CLP</MenuItem>
                <MenuItem value="PEN">PEN</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
              <FormHelperText>{touched.moneda && errors.moneda ? errors.moneda : ''}</FormHelperText>
            </FormControl>
          </Grid>
        )}

        <Grid item lg={3} md={3} sm={3} xs={6}>
          <CurrencyTextField
            label="Monto Neto"
            value={values.monto}
            fullWidth
            variant="outlined"
            margin={'normal'}
            onChange={(event, value) => formik.setFieldValue('monto', value)}
            error={touched.monto && errors.monto && Boolean(errors.monto)}
            helperText={touched.monto && errors.monto ? errors.monto : ''}
            currencySymbol="$"
            minimumValue="0"
            maximumValue="1000000000"
            outputFormat="string"
            decimalCharacter=","
            digitGroupSeparator="."
            leading="deny"
            decimalPlaces={4}
            decimalPlacesShownOnBlur={2}
            decimalPlacesShownOnFocus={4}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={4} md={4} sm={4} xs={8}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              name="fecha"
              autoOk
              okLabel="Aceptar"
              cancelLabel="Cancelar"
              fullWidth
              InputLabelProps={{
                classes: {
                  shrink: 'shrink',
                },
              }}
              // views={['year']}
              margin="normal"
              inputVariant="outlined"
              label="Fecha Factura"
              format="dd/MM/yyyy"
              helperText={touched.fecha ? errors.fecha : ''}
              error={touched.fecha && Boolean(errors.fecha)}
              onChange={dateChange.bind(null, 'fecha')}
              value={values.fecha}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <FormControl fullWidth error={touched.file && errors.file && Boolean(errors.file)}>
            <InputLabel shrink className={classes.fileInput}>
              Archivo de Factura
            </InputLabel>
            <FileInput
              inputName="file"
              meta={{ touched: touched.file, errors: errors.file }}
              buttonColor="primary"
              buttonVariant="outlined"
              label="Archivo de Factura"
              value={values.file}
              handleOnChange={fileChange.bind(null, 'file')}
              textVariant="outlined"
              accept="image/x-png,image/gif,image/jpeg,application/pdf"
            />
            <FormHelperText>{touched.file && errors.file ? errors.file : ''}</FormHelperText>
          </FormControl>
        </Grid>
        {permisos && permisos[ASIGNAR_ACTIVOS_PERSONAS] && (
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <FormControlLabel
              color={'primary'}
              style={{ marginTop: '24px' }}
              control={
                <Checkbox
                  checked={values.solicitar_activacion}
                  color="primary"
                  onChange={(e) => {
                    formik.setFieldValue('solicitar_activacion', e.target.checked);
                  }}
                />
              }
              label="Es Activable"
            />
          </Grid>
        )}
      </Grid>
      {!modoMultiplesActivos && <TablaDocumentosAdjuntos formik={formik} />}

      <Grid container alignContent="center" justify="center">
        <Grid item lg md sm xs className={classes.center}>
          <Button variant="outlined" className={classes.btnMargin} onClick={() => handleVolver(values)}>
            Volver
          </Button>
          <Button type="submit" color="primary" variant="contained" className={classes.btnMargin}>
            Guardar
          </Button>
        </Grid>
      </Grid>

      <DialogoAgregarProveedor
        open={openDialogoProveedor}
        closeDialog={() => setOpenDialogoProveedor(false)}
        onSubmit={refreshProveedores}
      />
    </form>
  );
}
