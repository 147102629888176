import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import FileInput from '../../../components/fileInput';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import MaterialCreatable from '../../../components/materialCreatable';
import useSWR from 'swr';
import { getSeleccionables } from '../../../services/request/requestSeleccionables';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { getMunicipalidades } from '../../../services/request/requestMunicipalidades';
import { getAseguradoras } from '../../../services/request/requestAseguradoras';

async function getDataMunicipalidades(filter) {
  const response = await getMunicipalidades();
  if (response && response.status) {
    return response.data.map((a) => ({
      value: a,
      label: a.nombre,
    }));
  } else {
    return [];
  }
}

async function getDataAseguradoras(filter) {
  const response = await getAseguradoras();
  if (response && response.status) {
    return response.data.map((a) => ({
      value: a,
      label: a.aseguradora,
    }));
  } else {
    return [];
  }
}

export default function TablaDocumentosAdjuntos(props) {
  const { formik } = props;
  const { values, setFieldValue, setFieldTouched } = formik;

  const fileChange = (name, e) => {
    setFieldValue(name, e.target.files[0]);
    setFieldTouched(name, true, false);
  };
  const dateChange = (name, e) => {
    if (e) setFieldValue(name, e);
    else setFieldValue(name, null);
    setFieldTouched(name, true, false);
  };

  const [contador, setContador] = useState(0);

  const { data: tiposDocumentos } = useSWR(
    'get:tipos_documentos',
    (key) => getSeleccionables({ context_eq: 'tipo_documento_adjunto' }),
    { refreshInterval: 0 },
  );
  const { data: municipalidades } = useSWR('get:municipalidades', (key) => getDataMunicipalidades({}), {
    refreshInterval: 0,
  });
  const { data: aseguradoras } = useSWR('get:aseguradoras', (key) => getDataAseguradoras({}), { refreshInterval: 0 });

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <MaterialTable
          title="Otros documentos"
          options={{
            search: false,
            padding: 'dense',
          }}
          actions={[
            {
              icon: 'add',
              tooltip: 'Agregar Documento',
              isFreeAction: true,
              onClick: (event) => {
                const documento = {
                  archivo: null,
                  fecha: new Date(),
                  tipo_documento: '',
                  fecha_proxima_carga: null,
                };
                values.documentos.push(documento);
                setContador(contador + 1);
              },
            },
            {
              icon: 'delete',
              tooltip: 'Quitar',
              onClick: (event, rowData) => {
                const documentos = values.documentos.filter((value, index) => {
                  return index !== rowData.tableData.id;
                });
                setFieldValue('documentos', documentos);
              },
            },
          ]}
          localization={{
            body: {
              emptyDataSourceMessage: 'Presione botón (+) para agregar otros documentos',
            },
            header: { actions: '' },
            pagination: {
              labelRowsSelect: 'Filas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstAriaLabel: 'Primera Página',
              firstTooltip: 'Primera Página',
              previousAriaLabel: 'Página Anterior',
              previousTooltip: 'Página Anterior',
              nextAriaLabel: 'Siguiente Página',
              nextTooltip: 'Siguiente Página',
              lastAriaLabel: 'Última Página',
              lastTooltip: 'Última Página',
            },
          }}
          data={values.documentos}
          columns={[
            {
              title: 'Tipo',
              cellStyle: {
                minWidth: '200px',
              },
              render: (rowProps) => (
                <MaterialCreatable
                  name={`documentos[${rowProps.tableData.id}]['tipo_documento']`}
                  value={values.documentos[rowProps.tableData.id]['tipo_documento']}
                  onChange={(newValue, actionMeta) =>
                    newValue
                      ? setFieldValue(`documentos[${rowProps.tableData.id}]['tipo_documento']`, newValue)
                      : formik.setFieldValue(`documentos[${rowProps.tableData.id}]['tipo_documento']`, '')
                  }
                  placeholder="Ingresar Tipo de Documento"
                  options={tiposDocumentos}
                  noOptionsMessage={() => 'Ingresar un nuevo Tipo'}
                  margin="none"
                />
              ),
            },
            {
              title: 'Archivo',
              cellStyle: {
                minWidth: '300px',
              },
              render: (rowProps) => (
                <FileInput
                  inputName={`documentos[${rowProps.tableData.id}]['file']`}
                  buttonColor="primary"
                  buttonVariant="outlined"
                  label="Archivo"
                  value={values.documentos[rowProps.tableData.id]['file']}
                  handleOnChange={fileChange.bind(null, `documentos[${rowProps.tableData.id}]['file']`)}
                  textVariant="outlined"
                  margin="none"
                />
              ),
            },
            {
              title: 'Fecha Documento',
              cellStyle: {
                minWidth: '200px',
              },
              render: (rowProps) => (
                <KeyboardDatePicker
                  name={`documentos[${rowProps.tableData.id}]['fecha']`}
                  autoOk
                  fullWidth
                  format="dd/MM/yyyy"
                  onChange={dateChange.bind(null, `documentos[${rowProps.tableData.id}]['fecha']`)}
                  value={values.documentos[rowProps.tableData.id]['fecha']}
                  inputVariant="outlined"
                  margin="none"
                />
              ),
            },
            {
              title: 'Fecha Renovación',
              cellStyle: {
                minWidth: '200px',
              },
              render: (rowProps) => (
                <KeyboardDatePicker
                  name={`documentos[${rowProps.tableData.id}]['fecha_proxima_carga']`}
                  autoOk
                  fullWidth
                  format="dd/MM/yyyy"
                  onChange={dateChange.bind(null, `documentos[${rowProps.tableData.id}]['fecha_proxima_carga']`)}
                  value={values.documentos[rowProps.tableData.id]['fecha_proxima_carga']}
                  inputVariant="outlined"
                  margin="none"
                />
              ),
            },
            // {
            //   title: 'Valor',
            //   cellStyle: {
            //     minWidth: '100px',
            //   },
            //   render: (rowProps) => (
            //     <CurrencyTextField
            //       name={`documentos[${rowProps.tableData.id}]['valor_permiso_circulacion']`}
            //       label=""
            //       value={values.documentos[rowProps.tableData.id]['valor_permiso_circulacion']}
            //       fullWidth
            //       margin={'none'}
            //       onChange={(event, value) =>
            //         formik.setFieldValue(`documentos[${rowProps.tableData.id}]['valor_permiso_circulacion']`, value)
            //       }
            //       currencySymbol="$"
            //       minimumValue="0"
            //       maximumValue="1000000000"
            //       outputFormat="string"
            //       decimalCharacter=","
            //       digitGroupSeparator="."
            //       leading="deny"
            //       decimalPlaces={0}
            //       decimalPlacesShownOnBlur={0}
            //       decimalPlacesShownOnFocus={0}
            //     />
            //   ),
            // },
            {
              title: 'Valor',
              cellStyle: {
                minWidth: '100px',
              },
              render: (rowProps) => {
                const tipoDocumento = values.documentos[rowProps.tableData.id]['tipo_documento']?.label || '';
                console.log('tipoDocumento: ', tipoDocumento);

                if (!tipoDocumento.toLowerCase().includes('seguro')) {
                  // Mostrar campo de municipalidades si el tipo es "Permiso Circulación"
                  return (
                    <CurrencyTextField
                      name={`documentos[${rowProps.tableData.id}]['valor_permiso_circulacion']`}
                      label=""
                      value={values.documentos[rowProps.tableData.id]['valor_permiso_circulacion']}
                      fullWidth
                      margin={'none'}
                      onChange={(event, value) =>
                        formik.setFieldValue(`documentos[${rowProps.tableData.id}]['valor_permiso_circulacion']`, value)
                      }
                      currencySymbol="$"
                      minimumValue="0"
                      maximumValue="1000000000"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      leading="deny"
                      decimalPlaces={0}
                      decimalPlacesShownOnBlur={0}
                      decimalPlacesShownOnFocus={0}
                    />
                  );
                } else if (tipoDocumento.toLowerCase().includes('seguro')) {
                  // Mostrar campo de aseguradoras si el tipo contiene "Seguro"
                  return (
                    <CurrencyTextField
                      name={`documentos[${rowProps.tableData.id}]['valor_prima_seguro']`}
                      label=""
                      value={values.documentos[rowProps.tableData.id]['valor_prima_seguro']}
                      fullWidth
                      margin={'none'}
                      onChange={(event, value) =>
                        formik.setFieldValue(`documentos[${rowProps.tableData.id}]['valor_prima_seguro']`, value)
                      }
                      currencySymbol="$"
                      minimumValue="0"
                      maximumValue="1000000000"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      leading="deny"
                      decimalPlaces={0}
                      decimalPlacesShownOnBlur={0}
                      decimalPlacesShownOnFocus={0}
                    />
                  );
                }
                return null;
              },
            },
            // {
            //   title: 'Municipalidad/Aseguradora',
            //   cellStyle: {
            //     minWidth: '200px',
            //   },
            //   render: (rowProps) => (
            //     <MaterialCreatable
            //       name={`documentos[${rowProps.tableData.id}]['municipalidad']`}
            //       value={values.documentos[rowProps.tableData.id]['municipalidad']}
            //       onChange={(newValue, actionMeta) =>
            //         newValue
            //           ? setFieldValue(`documentos[${rowProps.tableData.id}]['municipalidad']`, newValue)
            //           : formik.setFieldValue(`documentos[${rowProps.tableData.id}]['municipalidad']`, '')
            //       }
            //       placeholder="Municipalidad"
            //       options={municipalidades}
            //       noOptionsMessage={() => 'Ingresar una nueva Municipalidad'}
            //       margin="none"
            //     />
            //   ),
            // },
            {
              title: 'Municipalidad/Aseguradora',
              cellStyle: {
                minWidth: '200px',
              },
              render: (rowProps) => {
                const tipoDocumento = values.documentos[rowProps.tableData.id]['tipo_documento']?.label || '';
                console.log('tipoDocumento: ', tipoDocumento);

                if (tipoDocumento.toLowerCase().includes('permiso circulación')) {
                  // Mostrar campo de municipalidades si el tipo es "Permiso Circulación"
                  return (
                    <MaterialCreatable
                      name={`documentos[${rowProps.tableData.id}]['municipalidad']`}
                      value={values.documentos[rowProps.tableData.id]['municipalidad']}
                      onChange={(newValue) =>
                        setFieldValue(`documentos[${rowProps.tableData.id}]['municipalidad']`, newValue)
                      }
                      placeholder="Municipalidad"
                      options={municipalidades}
                      noOptionsMessage={() => 'Ingresar una nueva Municipalidad'}
                      margin="none"
                    />
                  );
                } else if (tipoDocumento.toLowerCase().includes('seguro')) {
                  // Mostrar campo de aseguradoras si el tipo contiene "Seguro"
                  return (
                    <MaterialCreatable
                      name={`documentos[${rowProps.tableData.id}]['aseguradora']`}
                      value={values.documentos[rowProps.tableData.id]['aseguradora']}
                      onChange={(newValue) =>
                        setFieldValue(`documentos[${rowProps.tableData.id}]['aseguradora']`, newValue)
                      }
                      placeholder="Aseguradora"
                      options={aseguradoras}
                      noOptionsMessage={() => 'Ingresar una nueva Aseguradora'}
                      margin="none"
                    />
                  );
                }
                return null;
              },
            },
          ]}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
