import { Checkbox, FormControlLabel, Grid, makeStyles, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  margenSuperior: {
    marginTop: '15px',
  },
  items: {
    marginBottom: theme.spacing(0.7),
  },
}));

const ConfiguracionCostos = () => {
  const estilos = useStyles();

  return (
    <>
      <Grid container spacing={1} className={estilos.margenSuperior}>
        <Grid item xs={3}>
          <TextField
            label="Antigüedad Flota"
            name="antiguedad_flota"
            // value={values.descripcion}
            // onChange={handleChange}
            // error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
            // helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            label="Costo Financiero Deuda"
            name="costo_financiero_deuda"
            // value={values.descripcion}
            // onChange={handleChange}
            // error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
            // helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Costo Inscripción"
            name="costo_inscripcion"
            // value={values.descripcion}
            // onChange={handleChange}
            // error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
            // helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Valor Seguro SOAP"
            name="seguro_soap"
            // value={values.descripcion}
            // onChange={handleChange}
            // error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
            // helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Costo Seguro"
            name="costo_seguro"
            // value={values.descripcion}
            // onChange={handleChange}
            // error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
            // helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Checkbox
                //checked={values.sistema_luces}
                // onChange={(e) => setFieldValue('sistema_luces', e.target.checked)}
                name="usar_valores_de_configuracion"
                color="primary"
              />
            }
            label="Utilizar valores seteados para cálculo de Costos de Gestión"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} className={estilos.margenSuperior}>
        <Grid item xs={3}>
          <TextField
            label="Valor UF"
            name="valor_uf"
            // value={values.descripcion}
            // onChange={handleChange}
            // error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
            // helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            label="Fecha de UF"
            name="fecha_uf"
            // value={values.fecha_documento}
            // onChange={dateChange.bind(null, 'fecha_documento')}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            maxDate={new Date()}
            cancelLabel="Cancelar"
            okLabel="Aceptar"
            autoOk
            fullWidth
            size="small"
            // className={classes.input_required}
            // error={touched.fecha_documento && errors.fecha_documento && Boolean(errors.fecha_documento)}
            // helperText={touched.fecha_documento && errors.fecha_documento ? errors.fecha_documento : ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} className={estilos.margenSuperior}>
        <Grid item xs={3}>
          <TextField
            label="Antigüedad para PC - IMP"
            name="antiguedad_computador"
            // value={values.descripcion}
            // onChange={handleChange}
            // error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
            // helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Antigüedad para Topo - Lab"
            name="antiguedad_topografia"
            // value={values.descripcion}
            // onChange={handleChange}
            // error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
            // helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ''}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ConfiguracionCostos;
