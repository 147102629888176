import axios from 'axios';
import { ADMINISTRACION_USUARIOS, BACKEND, PORTAFOLIO } from '../../constants/urls';

/**
 * Guardar Bien
 * @param {*} data datos del Bien
 * @param {*} cancelToken token para cancelacion
 */
export function postBien(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/bienes/`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

export function postBienSimple(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/bienes/simple`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Actualizar Bien
 * @param {String}  id
 * @param {Object} data datos del Bien
 * @param {*} cancelToken token para cancelacion
 */
export function putBien(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/bienes/${id}`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data : null));
}

/**
 * Eliminar Bien
 * @param {String}  id
 * @param {*} cancelToken token para cancelacion
 */
export function deleteBien(id, cancelToken = null) {
  return axios
    .delete(`${BACKEND}/bienes/${id}`, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data : null));
}

/**
 * Obtener Bien
 * @param {String}  id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getBien(id, selects, populates, cancelToken = null) {
  let url = `${BACKEND}/bienes/${id}`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  return axios
    .get(url, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data : null));
}

/**
 * Obtener Bienes
 * @param {Object} filter filtros
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getBienes(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/bienes`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  return axios
    .get(url, {
      params: filter,
      cancelToken,
    })
    .then((res) => (res.data ? res.data : null));
}

/**
 * Obtener Indicadores de Bienes
 * @param {Object} filter filtros
 * @param {*} cancelToken token para cancelacion
 */
export function getIndicadoresBienes(filter = {}, cancelToken = null) {
  const url = `${BACKEND}/bienes/indicadores`;
  return axios
    .post(
      url,
      {},
      {
        params: filter,
        cancelToken,
      },
    )
    .then((res) => (res.data ? res.data : null));
}

/**
 * Guardar Activo
 * @param {*} data datos del Activo
 * @param {*} cancelToken token para cancelacion
 */
export function postActivo(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/activos/`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Guardar Vehiculo
 * @param {*} data datos del Vehiculo
 * @param {*} cancelToken token para cancelacion
 */
export function postVehiculo(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/vehiculos/`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Modificar Vehiculo
 * @param {String} id
 * @param {*} data datos del Vehiculo
 * @param {*} cancelToken token para cancelacion
 */
export function putVehiculo(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/vehiculos/${id}`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Obtener Vehiculos
 * @param {Object} filter filtros
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getVehiculos(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/vehiculos`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  return axios
    .get(url, {
      params: filter,
      cancelToken,
    })
    .then((res) => (res.data ? res.data : null));
}

/**
 * Obtener Vehiculo
 * @param {String}  id
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getVehiculo(id, selects, populates, cancelToken = null) {
  let url = `${BACKEND}/vehiculos/${id}`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  return axios
    .get(url, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data : null));
}

/**
 * Obtener Vehiculos
 * @param {Object} filter filtros
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getRegistroKilometrajes(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/kilometrajes`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  return axios
    .get(url, {
      params: filter,
      cancelToken,
    })
    .then((res) => (res.data ? res.data : null));
}

/**
 * Guardar Vehiculo
 * @param {*} data datos del Vehiculo
 * @param {*} cancelToken token para cancelacion
 */
export function postRegistroKilometraje(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/kilometrajes/`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Guardar Equipo Computacional
 * @param {*} data datos del Equipo Computacional
 * @param {*} cancelToken token para cancelacion
 */
export function postEquipoComputacional(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/equipos-ti/`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Modificar Equipo Computacional
 * @param {String} id
 * @param {*} data datos del Equipo Computacional
 * @param {*} cancelToken token para cancelacion
 */
export function putEquipoComputacional(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/equipos-ti/${id}`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

let contador = 0;
/**
 * Obtener correlativo
 * @param {*} id id Grupo Contable
 * @param {*} cancelToken token para cancelacion
 */
export function getCorrelativoGrupoContable(id, cancelToken = null) {
  // return axios.get(`${BACKEND}/grupo-contable/${id}/correlativo`, data,
  //   {
  //     cancelToken: cancelToken
  //   }).then(res => res.data);
  return new Promise((resolve, reject) => {
    contador++;
    let correlativo = contador;
    if (correlativo < 10) correlativo = '0000' + correlativo;
    else if (correlativo < 100) correlativo = '000' + correlativo;
    else if (correlativo < 1000) correlativo = '00' + correlativo;
    else if (correlativo < 10000) correlativo = '0' + correlativo;

    if (id === '5f5b713b300968001859797d') correlativo = 'ET-PC-' + correlativo;
    else if (id === '5f5b79106e99d90011bddca8') correlativo = 'ET-V-' + correlativo;
    else if (id === '5f5b7980300968001859797e') correlativo = 'ET-ET-' + correlativo;
    else if (id === '5f5a5a196e99d90011bddca0') correlativo = 'ET-MU-' + correlativo;
    else correlativo = '';
    resolve({ data: { data: correlativo }, status: 200 });
  });
}

/**
 * Guardar Solicitud Activacion
 * @param {*} data datos de Solicitud Activacion
 * @param {*} cancelToken token para cancelacion
 */
export function postSolicitudActivacion(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/solicitudes-activacion/`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Obterner Documentos Adjunto
 * @param {*} filter
 * @param {Array} selects lista de campos a consultar
 * @param {Array} populates lista de campos a poblar
 * @param {*} cancelToken token para cancelacion
 */
export function getDocumentoAdjunto(filter = {}, selects = [], populates = [], cancelToken = null) {
  let url = `${BACKEND}/documentos-adjuntos`;
  if (selects)
    selects.forEach((select) => {
      url = url + '/' + select;
    });
  if (populates)
    populates.forEach((populate) => {
      url = url + '/' + populate;
    });
  return axios
    .get(url, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Guardar Documento Adjunto
 * @param {*} data datos del documento
 * @param {*} cancelToken token para cancelacion
 */
export function postDocumentoAdjunto(data, cancelToken = null) {
  return axios
    .post(`${BACKEND}/documentos-adjuntos/`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Actualizar Documento Adjunto
 * @param {*} id ID del documento
 * @param {*} data datos actualizados del documento
 * @param {*} cancelToken token para cancelacion
 */
export function putDocumentoAdjunto(id, data, cancelToken = null) {
  return axios
    .put(`${BACKEND}/documentos-adjuntos/${id}`, data, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Borrar Documento Adjunto
 * @param {*} id ID del documento
 * @param {*} cancelToken token para cancelacion
 */
export function deleteDocumentoAdjunto(id, cancelToken = null) {
  return axios
    .delete(`${BACKEND}/documentos-adjuntos/${id}`, {
      cancelToken,
    })
    .then((res) => (res.data ? res.data.data : null));
}

/**
 * Método encargado de obtener todos los bienes sin asignar.
 */
export async function obtenerBienes() {
  try {
    const url = `${BACKEND}/bienes?is_asignado_ne=true`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los bienes sin asignar.
 */
export async function obtenerBienesPorFecha(desde, hasta) {
  try {
    const url = `${BACKEND}/bienes/_sociedad_ref.nombre/_id?fecha_creacion_lt=${hasta}&fecha_creacion_gt=${desde}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

///////////////////////////////////////////

/**
 * Método encargado de obtener personas.
 */
export async function obtenerPersonas() {
  try {
    const url = `${ADMINISTRACION_USUARIOS}/personas`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

///////////////////////////////////////////

/**
 * Método encargado de obtener gerencias.
 */
export async function obtenerGerencias() {
  try {
    const url = `${PORTAFOLIO}/gerencias`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function obtenerCostosActivos(filter = {}, cancelToken = null) {
  const url = `${BACKEND}/costosgestion/`;
  return axios
    .get(url, {
      params: filter,
      cancelToken,
    })
    .then((res) => res.data);
}

/**
 * Método encargado de obtener contratos.
 */
export async function obtenerContratos() {
  try {
    const url = `${PORTAFOLIO}/contratos`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener bienes por ID de persona.
 * @param {*} usuarioID ID del usuario con el bien asignado.
 */
export async function obtenerBienesUsuario(usuarioID) {
  try {
    const url = `${BACKEND}/bienes/codigo_activo/condicion_actual/ubicacion/marca/modelo/serial_number/fecha_creacion/asignado_por/_usuario_ref.nombre/_usuario_ref.apellido_paterno/_usuario_ref.apellido_materno/_gerencia_ref.sigla/_gerencia_ref.nombre/_contrato_ref.codigo/_contrato_ref.nombre/_clase_ref.nombre?_asignado_ref_eq=${usuarioID}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener bien por ID.
 */
export async function obtenerDetalleBien(bienID) {
  try {
    const url = `${BACKEND}/bienes/${bienID}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener asignaciones de una persona
 * @param {*} usuarioID ID del usuario con el bien asignado.
 */
export async function obtenerAsignacionesBienes(usuarioID) {
  try {
    const url = `${BACKEND}/bienes?usuario_asignado_ref_eq=${usuarioID}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los bienes, por gerencia, contrato o tipo.
 * @param {*} gerencia Gerencia para filtrar.
 * @param {*} contrato Contrato para filtrar.
 * @param {*} tipo Tipo de bien para filtrar.
 */
export async function ObtenerBienesFiltros(gerencia = null, contrato = null, tipoRecurso = null, clase = null) {
  try {
    const url = `${BACKEND}/bienes/codigo_activo/marca/modelo/condicion_actual/ubicacion/_contrato_ref.nombre/_contrato_ref.codigo/_gerencia_ref.nombre/_gerencia_ref.sigla/_clase_ref.nombre/_clase_ref._tipo_recurso_ref?is_asignado_eq=false`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de marcar un bien como asignado.
 * @param {*} bienID ID del bien.
 */
export async function ActualizarAsignado(bienID, condicion_actual) {
  try {
    const url = `${BACKEND}/bienes/${bienID}`;
    const response = await axios.put(url, { is_asignado: true, condicion_actual });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los bienes.
 */
export async function ObtenerBienes(asignado = true) {
  try {
    const url = `${BACKEND}/bienes/_asignado_ref/_clase_ref/_gerencia_ref/_contrato_ref?is_eliminado_eq=false${
      asignado ? '&is_asignado_eq=true' : ''
    }`;
    const response = await axios.get(url, { is_eliminado: false });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los bienes de un usuario.
 */
export async function ObtenerBienesPorUsuario(usuarioID) {
  try {
    const url = `${BACKEND}/bienes/codigo_activo/marca/modelo/condicion_actual/ubicacion/_clase_ref.nombre?is_asignado=true&_asignado_ref_eq=${usuarioID}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los bienes de una gerencia.
 */
export async function ObtenerBienesPorGerencia(gerenciaID) {
  try {
    const url = `${BACKEND}/bienes/codigo_activo/marca/modelo/condicion_actual/ubicacion/_clase_ref.nombre/_gerencia_ref?_gerencia_ref_eq=${gerenciaID}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los bienes de un contrato.
 */
export async function ObtenerBienesPorContrato(contratoID) {
  try {
    const url = `${BACKEND}/bienes/codigo_activo/marca/modelo/condicion_actual/ubicacion/_clase_ref.nombre/_asignado_ref.nombre/_asignado_ref.apellido_paterno/_asignado_ref.apellido_materno/_contrato_ref?is_eliminado_eq=false&_contrato_ref_eq=${contratoID}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
