import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Tooltip,
  Collapse,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import CalcularTarifa from './CalcularTarifa';

const CalculoDiferenciaMeses = (fechaCarga, fechaProximaCarga) => {
  const fechaProxima = moment(fechaProximaCarga);
  const fecha = moment(fechaCarga);

  if (fechaProxima.isValid() && fecha.isValid()) {
    const diferenciaMeses = fechaProxima.diff(fecha, 'months');
    return diferenciaMeses;
  } else {
    return 1;
  }
};

const DialogoVerTarifa = ({ open, setOpen, activoSeleccionado }) => {
  const [isCalculaTarifaVisible, setIsCalculaTarifaVisible] = useState(false);
  console.log('activoSeleccionado', activoSeleccionado);
  const valorSeguroObligatorio =
    activoSeleccionado?.documentos_adjuntos?.find((adjunto) => adjunto.tipo === 'Seguro Obligatorio')
      ?.valor_prima_seguro || 0;
  const SeguroPerdidaTotal = activoSeleccionado?.documentos_adjuntos?.find(
    (adjunto) => adjunto.tipo === 'Seguro Pérdida Total (PT+RC)',
  );

  const valores = {
    Vehículos: [
      {
        label: 'Valor',
        valor: activoSeleccionado?.valor ? activoSeleccionado?.valor : 0,
        tipo: 'fijo',
        textoExplicativo: '(V.I) Valor de compra del bien definido en la factura',
      },
      // { label: 'Año', valor: activoSeleccionado.anio },
      {
        label: 'Valor Fijo',
        valor: activoSeleccionado?.valor_fijo,
        tipo: 'fijo',
        textoExplicativo: 'Valor Fijo definido por Contabilidad',
      },
      {
        label: 'Costo Inscripción',
        valor: activoSeleccionado?.valor_inscripcion,
        tipo: 'fijo',
        textoExplicativo: '(C.I) Valor de Inscipción en registro civil (Patente)',
      },
      {
        label: 'Valor Libro',
        valor: activoSeleccionado?.valor_libro,
        tipo: 'variable',
        textoExplicativo: '(V.L) Valor de amortización mensual del Activo',
      },
      {
        label: 'Costo Permiso Circulación',
        valor: activoSeleccionado?.valor_permiso_circulacion,
        tipo: 'fijo',
        textoExplicativo: '(C.P) Valor anual pagado por permiso de Circulación',
      },
      {
        label: 'Valor Promedio Venta',
        valor: activoSeleccionado?.valor_promedio_venta,
        tipo: 'fijo',
        textoExplicativo: '(V.P.V) Valor anual definido por contabilidad',
      },
      {
        label: 'Seguro Obligatorio',
        valor: valorSeguroObligatorio,
        tipo: 'fijo',
        textoExplicativo: '(SOAP) Valor del seguro Obligatorio SOAP ',
      },
      {
        label: 'Seguro Pérdida Total',
        valor: SeguroPerdidaTotal ? SeguroPerdidaTotal.valor_prima_seguro : 0,
        tipo: 'fijo',
        textoExplicativo: '(C.S) Valor de la prima del seguro Full (Pérdida Total PT + RC) ',
      },
    ],
    'Equipos Topográficos': [
      {
        label: 'Valor',
        valor: activoSeleccionado?.valor ? activoSeleccionado?.valor : 0,
        tipo: 'fijo',
        textoExplicativo: 'Valor Inicial de Activo',
      },
    ],
    'Equipos Laboratorio': [
      {
        label: 'Valor',
        valor: activoSeleccionado?.valor ? activoSeleccionado?.valor : 0,
        tipo: 'fijo',
        textoExplicativo: 'Valor Inicial de Activo',
      },
    ],
    Computadores: [
      {
        label: 'Valor',
        valor: activoSeleccionado?.valor ? activoSeleccionado?.valor : 0,
        tipo: 'fijo',
        textoExplicativo: 'Valor Inicial de Activo',
      },
    ],
    Impresoras: [
      {
        label: 'Valor',
        valor: activoSeleccionado?.valor ? activoSeleccionado?.valor : 0,
        tipo: 'fijo',
        textoExplicativo: 'Valor Inicial de Activo',
      },
    ],
    '': [{ label: 'Valor', valor: 0, tipo: 'fijo', textoExplicativo: 'Valor Inicial de Activo' }],
  };

  let tarifa = activoSeleccionado && activoSeleccionado?.clase.tarifa ? activoSeleccionado?.clase.tarifa : '';

  const handleClose = () => {
    tarifa = '';
    activoSeleccionado = {};
    setIsCalculaTarifaVisible(false);
    setOpen(false);
  };

  return (
    <Dialog open={open} maxWidth={'md'}>
      <DialogTitle>{`Detalle Tarifas de Gestión - Activo: ${activoSeleccionado?.codigo_activo}`}</DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: '15px' }}>
          <Alert
            icon={<InfoIcon />}
            severity="success"
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              border: '1px solid rgb(201, 235, 217, 0.5)',
            }}
            onClick={() => setIsCalculaTarifaVisible(!isCalculaTarifaVisible)}
          >
            {`Valor Tarifa de Gestión: ${new Intl.NumberFormat('es-CL', {
              style: 'currency',
              currency: 'CLP',
              minimumFractionDigits: 0,
            }).format(activoSeleccionado?.valor_tarifa)}`}
          </Alert>
          <Collapse in={isCalculaTarifaVisible}>
            <div
              style={{
                border: '1px solid rgb(201, 235, 217, 0.5)',
                padding: '10px', // Ajusta el espacio dentro del cuadro
              }}
            >
              <CalcularTarifa
                tipoTarifa={tarifa}
                anios={activoSeleccionado?.anio ? activoSeleccionado?.anio.toFixed(2) : 0}
                valorInicial={activoSeleccionado?.valor ? activoSeleccionado?.valor : 0}
              />
            </div>
            {/* <CalcularTarifa tipoTarifa={tarifa} /> */}
          </Collapse>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Card style={{ width: '23rem' }}>
            <CardContent>
              <Alert icon={false} severity="info" style={{ textAlign: 'center' }}>
                Valores Fijos Mensuales
              </Alert>
              <Table style={{ width: '100%', tableLayout: 'fixed' }}>
                <TableBody>
                  {valores[tarifa]
                    .filter((item) => item?.tipo === 'fijo')
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ width: 'auto', textAlign: 'right' }}>
                          <Tooltip title={`${item.textoExplicativo}`} arrow>
                            <span>{item.label}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell style={{ width: '9rem' }}>
                          <Tooltip title={`${item.textoExplicativo}`} arrow>
                            <span>{`${new Intl.NumberFormat('es-CL', {
                              style: 'currency',
                              currency: 'CLP',
                              minimumFractionDigits: 0,
                            }).format(item.valor)}`}</span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          {
            //valores[tarifa].filter((item) => item.tipo === 'variable').length > 0 && (
            <Card style={{ width: '23rem' }}>
              <CardContent>
                <Alert style={{ textAlign: 'center' }} icon={false} severity="info">
                  Parámetros del Activo
                </Alert>
                <Table style={{ width: '100%', tableLayout: 'fixed' }}>
                  <TableBody>
                    {valores[tarifa]
                      .filter((item) => item.tipo === 'variable')
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ width: 'auto', textAlign: 'right' }}>{item.label}</TableCell>
                          <TableCell style={{ width: '10rem' }}>
                            {`${new Intl.NumberFormat('es-CL', {
                              style: 'currency',
                              currency: 'CLP',
                              minimumFractionDigits: 0,
                            }).format(item.valor)}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell style={{ width: 'auto', textAlign: 'right' }}>Años Antigüedad</TableCell>
                      <TableCell style={{ width: '9rem' }}>{`${
                        activoSeleccionado?.anio ? activoSeleccionado?.anio.toFixed(2) : 0
                      } años`}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: 'auto', textAlign: 'right' }}>Tipo Tarifa</TableCell>
                      <TableCell style={{ width: '9rem' }}>{`${tarifa ? tarifa : 'No definida'}`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
            // )
          }
        </div>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cerrar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogoVerTarifa;
