import React, { useEffect, useContext, useCallback, Fragment, useState } from 'react';
import Page from './pageFormularioActivacion';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RegistroBienesContext } from '.';
import moment from 'moment';
import useSWR from 'swr';
import ExcelJS from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';
import { getProveedores } from '../../../services/request/requestTipoProveedores';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  postBien,
  postEquipoComputacional,
  postVehiculo,
  putBien,
  postDocumentoAdjunto,
} from '../../../services/request/requestBienes';
import { MainContext } from '../../../App';
import { postMarca } from '../../../services/request/requestMarcas';
import { uploadFileToDrive, uploadFileToCloudStorage } from '../../../services/fileUpload';
import { GenerarLog } from '../../../services/activoLog';
import DialogoCargaRegistro from './dialogoCargaRegistro';
import { useHistory } from 'react-router';
import * as ROUTES from '../../../constants/routes';
import { FRONTEND } from '../../../constants/urls';
import { postModelo } from '../../../services/request/requestModelos';
import { getDocumentosRequeridosClase } from '../../../services/request/requestDocumentosRequeridosClase';
import { postMunicipalidad } from '../../../services/request/requestMunicipalidades';
const validationSchema = yup.object({});

export default function FormularioActivacion() {
  const { data: proveedores, mutate: refreshProveedores } = useSWR('get:proveedores', (key) => getProveedores({}), {
    refreshInterval: 0,
  });

  const notistack = useSnackbar();
  const history = useHistory();
  const {
    formularioActivacion,
    formularioBasico,
    formularioComputacionales,
    formularioVehiculos,
    showTabComputacionales,
    showTabVehiculos,
    setFormularioActivacion,
    setFormularioBasico,
    setFormularioVehiculos,
    setFormularioComputacionales,
    setSelectedTab,
    setShowTabComputacionales,
    setShowTabVehiculos,
  } = useContext(RegistroBienesContext);
  const [bien, setBien] = useState({ _id: '', codigo_activo: '' });
  const [multipleBien, setMultipleBien] = useState([]);
  const { usuarioSesion } = useContext(MainContext);
  const [openDialogoCargaRegistro, setOpenDialogoCargaRegistro] = useState(false);
  const [mensajeDialogoCargaRegistro, setMensajeDialogoCargaRegistro] = useState('');
  const formik = useFormik({
    initialValues: {
      archivo: null,
      fecha: new Date(),
      monto: 0,
      numero: '',
      proveedor: null,
      factura: null,
      documentos: [],
      solicitar_activacion: true,
    },
    onSubmit: async (values) => {
      let key;
      let msjError = '';
      try {
        if (formularioBasico.modoMultiplesActivos) {
          let listaBienes = [];
          let multiplesActivos = formularioBasico.multiplesActivos;
          let listaCodigos = '';
          for (let i = 0; i < multiplesActivos.length; i++) {
            let desde = i + 1;
            let hasta = multiplesActivos.length;
            msjError = ' ' + desde + ' en adelante';
            key = notistack.enqueueSnackbar('Guardando Activo ' + desde + ' de ' + hasta + '...', {
              persist: true,
              anchorOrigin: {
                horizontal: 'center',
                vertical: 'bottom',
              },
            });
            const dataNuevoActivo = { ...formularioBasico };
            const bien = await postBien({
              _grupo_contable_ref: formularioBasico._grupo_contable_ref,
              activar: values.solicitar_activacion ? true : false,
            });
            if (multiplesActivos[i].file) {
              const uploadedImagenRefFile = await uploadFileToCloudStorage(
                multiplesActivos[i].file,
                `activos/${bien.codigo_activo}`,
                multiplesActivos[i].file.name,
              );
              dataNuevoActivo['foto_actual'] = uploadedImagenRefFile.url;
            }

            let vehiculo = null;
            let computacional = null;
            if (showTabComputacionales) {
              computacional = await postEquipoComputacional({
                disco_duro: multiplesActivos[i].disco_duro,
                procesador: multiplesActivos[i].procesador,
                memoria_ram: multiplesActivos[i].memoria_ram,
                pulgadas_pantalla: multiplesActivos[i].pulgadas_pantalla,
              });
            }
            if (showTabVehiculos) {
              vehiculo = await postVehiculo({
                kilometraje: multiplesActivos[i].kilometraje,
                numero_motor: multiplesActivos[i].numero_motor,
                numero_chasis: multiplesActivos[i].numero_chasis,
                color: multiplesActivos[i].color,
                ano: moment(multiplesActivos[i].ano).format('YYYY'),
                tipo_combustible: multiplesActivos[i].tipo_combustible,
                transmision: multiplesActivos[i].transmision,
                cilindrada: multiplesActivos[i].cilindrada,
                patente: multiplesActivos[i].patente,
                tag: multiplesActivos[i].tag,
                gps: multiplesActivos[i].gps,
                mobileye: multiplesActivos[i].mobileye,
                barra_antivuelco: multiplesActivos[i].barra_antivuelco,
              });
            }

            if (values.file) {
              const uploadedFile = await uploadFileToDrive(
                values.file,
                `activos/${bien.codigo_activo}/${values.file.name}`,
              );
              values.factura = uploadedFile;
            }

            //CREAR DOCUMENTOS REQUERIDOS SI ES QUE NO SE SUBIERON ANTERIORMENTE
            const responseDocumentosRequeridos = await getDocumentosRequeridosClase({
              _clase_ref_eq: formularioBasico._clase_ref,
            });
            if (responseDocumentosRequeridos.status) {
              for (let d = 0; d < responseDocumentosRequeridos.data.length; d++) {
                // const archivoBien = values.documentos.find(
                //   a => a.tipo_documento.label === responseDocumentosRequeridos.data[d].nombre
                // );
                // if (!archivoBien) {
                await postDocumentoAdjunto({
                  tipo: responseDocumentosRequeridos.data[d].nombre,
                  _bien_ref: bien._id,
                  archivo: null,
                });
                // }
              }
            }

            let marca = multiplesActivos[i].marca_obj.value;
            if (multiplesActivos[i].marca_obj && multiplesActivos[i].marca_obj.__isNew__) {
              marca = await postMarca({
                nombre: multiplesActivos[i].marca_obj.value,
                is_computacional: multiplesActivos[i].marca_obj.is_computacional
                  ? multiplesActivos[i].marca_obj.is_computacional
                  : false,
                is_vehiculo: multiplesActivos[i].marca_obj.is_vehiculo
                  ? multiplesActivos[i].marca_obj.is_vehiculo
                  : false,
                descripcion: multiplesActivos[i].marca_obj.value,
              });
              marca = marca.data;
              dataNuevoActivo._marca_ref = marca._id;
            } else {
              dataNuevoActivo._marca_ref = multiplesActivos[i].marca_obj.value._id;
            }

            if (multiplesActivos[i].modelo_obj && multiplesActivos[i].modelo_obj.__isNew__) {
              const nuevoModelo = await postModelo({
                _marca_ref: marca._id,
                nombre: multiplesActivos[i].modelo_obj.value,
              });
              dataNuevoActivo._modelo_ref = nuevoModelo.data._id;
            } else {
              dataNuevoActivo._modelo_ref = multiplesActivos[i].modelo_obj.value._id;
            }

            let dataCreacion = {
              ...dataNuevoActivo,
              multiplesActivos: null,
              serial_number: multiplesActivos[i].serial_number,
              condicion_actual: multiplesActivos[i].condicion_actual,
              descripcion: multiplesActivos[i].descripcion,
              marca: multiplesActivos[i].marca_obj?.value?.nombre
                ? multiplesActivos[i].marca_obj.value.nombre
                : multiplesActivos[i].marca_obj.value,
              modelo: multiplesActivos[i].modelo_obj?.value?.nombre
                ? multiplesActivos[i].modelo_obj.value.nombre
                : multiplesActivos[i].modelo_obj.value,
              is_no_activable: !values.solicitar_activacion,
              _vehiculo_ref: vehiculo ? vehiculo._id : null,
              _equipo_ti_ref: computacional ? computacional._id : null,
              activo: {
                factura: {
                  numero: values.numero,
                  archivo: values.factura,
                  proveedor: values.proveedor ? values.proveedor.value : null,
                  fecha: values.fecha,
                },
                valor: values.monto,
                moneda: values.moneda,
                _propietario_ref: formularioBasico.propietario ? formularioBasico.propietario.value : null,
                ubicacion: '',
                descripcion: '',
              },
            };

            await putBien(bien._id, dataCreacion);

            let registroLog = {
              _bien_ref: bien._id,
              titulo: 'Creación de Activo',
              cuerpo: `Activo ${dataCreacion.marca} ${dataCreacion.modelo} de tipo ${formularioBasico.clase.value._tipo_recurso_ref.nombre} ingresado por ${usuarioSesion.nombre} para el contrato ${formularioBasico.contrato.label}.`,
              tipo: 'creacion_activo',
              icon: 'add',
            };
            await GenerarLog(registroLog);

            if (values.solicitar_activacion) {
              let datosActualizacion = {
                is_activo_contable: true,
                is_no_activable: false,
                fecha_activacion: new Date(),
                estado_activacion: 'Aprobado Registro Contable',
              };
              let registroLog = {
                _bien_ref: bien._id,
                titulo: 'Activación de Contable',
                cuerpo: `El usuario ${usuarioSesion.nombre} realizó la activación del activo ${bien.codigo_activo} mediante registro de activo.`,
                tipo: 'solicitud_activacion',
                icon: 'check_circle_outline',
              };
              await Promise.all([putBien(bien._id, datosActualizacion), GenerarLog(registroLog)]);
            }
            listaCodigos += listaCodigos == '' ? bien.codigo_activo : ', ' + bien.codigo_activo;
            listaBienes.push({ ...dataCreacion, ...bien });
            notistack.closeSnackbar(key);
          }

          setMultipleBien(listaBienes);
          setMensajeDialogoCargaRegistro(listaCodigos);
          setOpenDialogoCargaRegistro(true);
        } else {
          key = notistack.enqueueSnackbar('Guardando Activo...', {
            persist: true,
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
          });

          const bien = await postBien({
            _grupo_contable_ref: formularioBasico._grupo_contable_ref,
            activar: values.solicitar_activacion ? true : false,
          });
          if (formularioBasico.file) {
            const uploadedImagenRefFile = await uploadFileToCloudStorage(
              formularioBasico.file,
              `activos/${bien.codigo_activo}`,
              formularioBasico.file.name,
            );
            formularioBasico['foto_actual'] = uploadedImagenRefFile.url;
          }

          setBien(bien);
          let vehiculo = null;
          let computacional = null;
          if (formularioComputacionales) {
            computacional = await postEquipoComputacional(formularioComputacionales);
          }
          if (formularioVehiculos) {
            formularioVehiculos.ano = moment(formularioVehiculos.ano).format('YYYY');
            vehiculo = await postVehiculo(formularioVehiculos);
          }

          if (values.file) {
            const uploadedFile = await uploadFileToDrive(
              values.file,
              `activos/${bien.codigo_activo}/${values.file.name}`,
            );
            values.factura = uploadedFile;
          }

          //SUBIR DOCUMENTOS ADJUNTOS
          for (let i in values.documentos) {
            const documento = values.documentos[i];
            const uploadedFile = await uploadFileToDrive(
              documento.file,
              `activos/${bien.codigo_activo}/${documento.file.name}`,
            );
            documento['archivo'] = uploadedFile;
            documento['_bien_ref'] = bien._id;
            documento['tipo'] = documento.tipo_documento.label;
            if (documento.municipalidad && documento.municipalidad.__isNew__)
              await postMunicipalidad({
                nombre: documento.municipalidad.value,
              });
            documento['municipalidad'] =
              documento.municipalidad && documento.municipalidad.label ? documento.municipalidad.label : '';
            documento['valor_permiso_circulacion'] = documento.valor_permiso_circulacion
              ? documento.valor_permiso_circulacion
              : 0;
            if (documento.tipo === 'Permiso Circulación')
              formularioBasico['valor_permiso_circulacion'] = documento.valor_permiso_circulacion;
            await postDocumentoAdjunto(documento);
          }

          //CREAR DOCUMENTOS REQUERIDOS SI ES QUE NO SE SUBIERON ANTERIORMENTE
          const responseDocumentosRequeridos = await getDocumentosRequeridosClase({
            _clase_ref_eq: formularioBasico._clase_ref,
          });
          if (responseDocumentosRequeridos.status) {
            for (let d = 0; d < responseDocumentosRequeridos.data.length; d++) {
              const archivoBien = values.documentos.find(
                (a) => a.tipo_documento.label === responseDocumentosRequeridos.data[d].nombre,
              );
              if (!archivoBien) {
                await postDocumentoAdjunto({
                  tipo: responseDocumentosRequeridos.data[d].nombre,
                  _bien_ref: bien._id,
                  archivo: null,
                });
              }
            }
          }

          if (formularioBasico) {
            let marca = formularioBasico.marca_obj.value;
            if (formularioBasico.marca_obj && formularioBasico.marca_obj.__isNew__) {
              marca = await postMarca({
                nombre: formularioBasico.marca_obj.value,
                is_computacional: formularioBasico.marca_obj.is_computacional
                  ? formularioBasico.marca_obj.is_computacional
                  : false,
                is_vehiculo: formularioBasico.marca_obj.is_vehiculo ? formularioBasico.marca_obj.is_vehiculo : false,
                descripcion: formularioBasico.marca_obj.value,
              });
              marca = marca.data;
              formularioBasico._marca_ref = marca._id;
            } else {
              formularioBasico._marca_ref = formularioBasico.marca_obj.value._id;
            }

            if (formularioBasico.modelo_obj && formularioBasico.modelo_obj.__isNew__) {
              const nuevoModelo = await postModelo({
                _marca_ref: marca._id,
                nombre: formularioBasico.modelo_obj.value,
              });
              formularioBasico._modelo_ref = nuevoModelo.data._id;
            } else {
              formularioBasico._modelo_ref = formularioBasico.modelo_obj.value._id;
            }

            await putBien(bien._id, {
              ...formularioBasico,
              is_no_activable: !values.solicitar_activacion,
              _vehiculo_ref: vehiculo ? vehiculo._id : null,
              _equipo_ti_ref: computacional ? computacional._id : null,
              activo: {
                factura: {
                  numero: values.numero,
                  archivo: values.factura,
                  proveedor: values.proveedor ? values.proveedor.value : null,
                  fecha: values.fecha,
                },
                valor: values.monto,
                moneda: values.moneda,
                _propietario_ref: formularioBasico.propietario ? formularioBasico.propietario.value : null,
                ubicacion: '',
                descripcion: '',
              },
            });

            let registroLog = {
              _bien_ref: bien._id,
              titulo: 'Creación de Activo',
              cuerpo: `Activo ${formularioBasico.marca} ${formularioBasico.modelo} de tipo ${formularioBasico.clase.value._tipo_recurso_ref.nombre} ingresado por ${usuarioSesion.nombre} para el contrato ${formularioBasico.contrato.label}.`,
              tipo: 'creacion_activo',
              icon: 'add',
            };
            await GenerarLog(registroLog);

            if (values.solicitar_activacion) {
              let datosActualizacion = {
                is_activo_contable: true,
                is_no_activable: false,
                fecha_activacion: new Date(),
                estado_activacion: 'Aprobado Registro Contable',
              };
              let registroLog = {
                _bien_ref: bien._id,
                titulo: 'Activación de Contable',
                cuerpo: `El usuario ${usuarioSesion.nombre} realizó la activación del activo ${bien.codigo_activo} mediante registro de activo.`,
                tipo: 'solicitud_activacion',
                icon: 'check_circle_outline',
              };
              await Promise.all([putBien(bien._id, datosActualizacion), GenerarLog(registroLog)]);
            }
          }
          notistack.closeSnackbar(key);
          setMensajeDialogoCargaRegistro(`Activo Creado: ${bien.codigo_activo}`);
          setOpenDialogoCargaRegistro(true);
        }
      } catch (e) {
        console.log(e);
        notistack.closeSnackbar(key);
        const errorkey = notistack.enqueueSnackbar('Error: No ha sido posible crear el Activo' + msjError, {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(errorkey)}>
              <Close />
            </IconButton>
          ),
        });
      }
    },
    validationSchema,
  });

  const { resetForm } = formik;

  const resetFormulario = useCallback(() => {
    if (formularioActivacion) {
      resetForm({ values: formularioActivacion });
    } else resetForm();
  }, [formularioActivacion, resetForm]);

  useEffect(() => {
    resetFormulario();
  }, [resetFormulario]);

  const handleVolver = (values) => {
    setSelectedTab(0);
    setFormularioActivacion(values);
  };

  const handleDescargarExcel = () => {
    let data = [...multipleBien];
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Activos');
    ws.columns = [
      { header: 'Código', width: 15 },
      { header: 'Url', width: 60 },
      { header: 'Descripción', width: 30 },
      { header: 'Marca', width: 15 },
      { header: 'Modelo', width: 15 },
      { header: 'Nº de Serie', width: 20 },
    ];

    ws.getRow(1).font = { bold: true };

    for (var i = 0; i < data.length; i++) {
      ws.addRow([
        data[i].codigo_activo,
        `${FRONTEND}/activos/${data[i]._id}`,
        data[i].descripcion,
        data[i].marca,
        data[i].modelo,
        data[i].serial_number,
      ]);
    }

    wb.xlsx
      .writeBuffer()
      .then(function (bufferData) {
        var blob = new Blob([bufferData], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'Activos Creados ' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xlsx');
      })
      .catch(function (error) {
        console.log('error en excel:', error);
        const errorkey = notistack.enqueueSnackbar('Error: No ha sido posible crear el Excel', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          action: (
            <IconButton onClick={() => notistack.closeSnackbar(errorkey)}>
              <Close />
            </IconButton>
          ),
        });
      });
  };

  const handleVolverActivos = () => {
    history.push(ROUTES.LISTADO_BIENES);
  };

  const handleIngresarNuevo = () => {
    setSelectedTab(0);
    setFormularioActivacion(null);
    setFormularioBasico(null);
    setFormularioVehiculos(null);
    setFormularioComputacionales(null);
    setShowTabVehiculos(false);
    setShowTabComputacionales(false);
    resetFormulario();
  };

  const handleIngresarNuevoMismosDatos = () => {
    setSelectedTab(0);
    setFormularioActivacion(null);
    setFormularioBasico({ ...formularioBasico, serial_number: '' });
    if (formularioVehiculos)
      setFormularioVehiculos({
        ...formularioVehiculos,
        numero_motor: '',
        numero_chasis: '',
        patente: '',
        tag: '',
      });
    // setFormularioComputacionales(null);
    // setShowTabVehiculos(false);
    // setShowTabComputacionales(false);
    // resetFormulario();
  };

  return (
    <Fragment>
      <Page
        formik={formik}
        proveedores={proveedores}
        handleVolver={handleVolver}
        refreshProveedores={refreshProveedores}
        modoMultiplesActivos={formularioBasico?.modoMultiplesActivos}
      />
      <DialogoCargaRegistro
        open={openDialogoCargaRegistro}
        setOpen={setOpenDialogoCargaRegistro}
        bien={bien}
        handleClose={() => setOpenDialogoCargaRegistro(false)}
        message={mensajeDialogoCargaRegistro}
        modoMultiplesActivos={formularioBasico?.modoMultiplesActivos}
        handleDescargarExcel={handleDescargarExcel}
        handleVolverActivos={handleVolverActivos}
        handleIngresarNuevo={handleIngresarNuevo}
        handleIngresarNuevoMismosDatos={handleIngresarNuevoMismosDatos}
      />
    </Fragment>
  );
}
